import * as actionTypes from "../../actions/userInvestment/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  userInvestments: [],
  successAlert: true,
  isSpinning: false,
};

export const userInvestmentStart = (state, action) => {
  return updateObject(state, {
    isSpinning: true,
    error: [],
  });
};
export const userInvestments = (state, action) => {
  return updateObject(state, {
    userInvestments: action.response,
    successAlert: false,
  });
};
export const userInvestmentFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    successAlert: false,
    isSpinning: false,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_INVESTMENT_START:
      return userInvestmentStart(state, action);
    case actionTypes.GET_USER_INVESTMENTS_SUCCESS:
      return userInvestments(state, action);
    case actionTypes.GET_USER_INVESTMENTS_FAIL:
      return userInvestmentFail(state, action);
    default:
      return state;
  }
};
export default reducer;
