export const NEWSLETTER_START = "NEWSLETTER_START";
export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const GET_NEWSLETTER = "GET_NEWSLETTER";
export const GET_NEWSLETTERS_SUCCESS = "GET_NEWSLETTERS_SUCCESS";
export const GET_NEWSLETTER_SUCCESS = "GET_NEWSLETTER_SUCCESS";
export const NEWSLETTER_FAIL = "NEWSLETTER_FAIL";
export const UPDATE_NEWSLETTER = "UPDATE_NEWSLETTER";
export const UPDATE_NEWSLETTER_SUCCESS = "UPDATE_NEWSLETTER_SUCCESS";
export const DELETE_NEWSLETTER = "DELETE_NEWSLETTER";
export const DELETE_NEWSLETTER_SUCCESS = "DELETE_NEWSLETTER_SUCCESS";
export const GET_SEARCH_NEWSLETTERS = "GET_SEARCH_NEWSLETTERS";

