export const SPAM_START = "SPAM_START";
export const SPAM_FAIL = "SPAM_FAIL";
export const SPAM_SUCCESS = "SPAM_SUCCESS";
export const SPAM_UPDATE_SUCCESS = "SPAM_UPDATE_SUCCESS";
export const GET_SPAM_SETTING = "GET_SPAM_SETTING";
export const GET_SPAM_SETTING_SUCCESS = "GET_SPAM_SETTING_SUCCESS";
export const CREATE_SPAM_SETTING = "CREATE_SPAM_SETTING";
export const UPDATE_SPAM_SETTING = "UPDATE_SPAM_SETTING";
export const GET_SPAMMER = "GET_SPAMMER";
export const GET_SPAMMER_SUCCESS = "GET_SPAMMER_SUCCESS";
export const GET_SPAMMERS = "GET_SPAMMERS";
export const GET_SPAMMERS_SUCCESS = "GET_SPAMMERS_SUCCESS";
export const CREATE_SPAMMER = "CREATE_SPAMMER";
export const CREATE_SPAMMER_SUCCESS = "CREATE_SPAMMER_SUCCESS";
export const UPDATE_SPAMMER = "UPDATE_SPAMMER";
export const UPDATE_SPAMMER_SUCCESS = "UPDATE_SPAMMER_SUCCESS";
export const DELETE_SPAMMER = "DELETE_SPAMMER";
export const DELETE_SPAMMER_SUCCESS = "DELETE_SPAMMER_SUCCESS";
export const BULK_UPDATE_SPAMMER_SUCCESS = "BULK_UPDATE_SPAMMER_SUCCESS";
export const BULK_UPDATE_SPAMMER = "BULK_UPDATE_SPAMMER";
export const BULK_DELETE_SPAMMER = "BULK_DELETE_SPAMMER";
export const BULK_DELETE_SPAMMER_SUCCESS = "BULK_DELETE_SPAMMER_SUCCESS";
