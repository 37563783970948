import * as actionTypes from "./actionTypes";

export const paymentGatewayStart = () => {
  return {
    type: actionTypes.PAYMENT_GATEWAY_START,
  };
};

export const paymentGatewayFail = (error) => {
  return {
    type: actionTypes.PAYMENT_GATEWAY_FAIL,
    error,
  };
};

/**
 * @description We request for the payment type data as there can be only one `paymentType` per section
 * @example Paypal Stripe Wepay Offline
 * @param {String} paymentType `String` : `paypal` `stripe` `wepay` `offline`
 * @see `admin/payment-gateway/:paymentType`
 */
export const getPaymentGateway = (paymentType) => {
  return {
    type: actionTypes.GET_PAYMENT_GATEWAY,
    paymentType,
  };
};

/**
 * We get the axios/saga response back from the server.
 * @param {JSON} response `JSON`
 */
export const getPaymentGatewaySuccessful = (response) => {
  return {
    type: actionTypes.GET_PAYMENT_GATEWAY_SUCCESSFUL,
    response,
  };
};

/**
 *
 * @param {JSON} paymentDetails `JSON`
 */
export const createPaymentGateway = (paymentDetails) => {
  return {
    type: actionTypes.CREATE_PAYMENT_GATEWAY,
    paymentDetails,
  };
};

/**
 *
 * @param {JSON} paymentDetails `JSON`
 */
export const updatePaymentGateway = (paymentDetails) => {
  return {
    type: actionTypes.UPDATE_PAYMENT_GATEWAY,
    paymentDetails,
  };
};

export const paymentGatewaySuccess = (response) => {
  return {
    type: actionTypes.PAYMENT_GATEWAY_SUCCESS,
    response,
  };
};
