/* eslint-disable no-unused-vars */
import { put, call } from 'redux-saga/effects';
import axios from '../../config/AxiosConfig';
import { COUNTRIES_API, GET_LANGUAGES } from '../actions/apiCollections';
import {
  countriesStart,
  getCountriesSuccess,
  getActiveCountriesSuccess,
  countriesFail,
  getLanguageSuccess,
  createCountrySucces,
  getCountrySuccess,
  updateCountrySuccess,
  miscFail,
  miscStart,
} from '../actions/index';

export function* getCountriesSaga() {
  yield put(countriesStart());
  yield put(miscStart());
  try {
    const response = yield axios.get(`${COUNTRIES_API}`);
    yield put(getCountriesSuccess(response.data.data.data));
  } catch (error) {
    yield put(countriesFail(error));
  }
}

export function* getActiveCountriesSaga() {
  yield put(countriesStart());
  yield put(miscStart());
  try {
    const response = yield axios.get(`${COUNTRIES_API}?status=true`);
    yield put(getActiveCountriesSuccess(response.data.data.data));
  } catch (error) {
    yield put(countriesFail(error));
  }
}

export function* getLanguageSaga() {
  try {
    const response = yield axios.get(`${GET_LANGUAGES}?fields=id,name`);
    yield put(getLanguageSuccess(response.data.data.data[0].id));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* createCountrySaga(action) {
  try {
    const response = yield axios.post(
      `${COUNTRIES_API}?lng=en`,
      action.countryData
    );
    yield put(createCountrySucces(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* getCountrySaga(action) {
  try {
    const response = yield axios.get(`${COUNTRIES_API}${action.id}`);
    yield put(getCountrySuccess(response.data.data.data));
  } catch (error) {
    yield put(countriesFail(error));
  }
}

export function* updateCountrySaga(action) {
  try {
    const response = yield axios.patch(
      `${COUNTRIES_API}${action.countryData.id}`,
      action.countryData
    );
    yield put(updateCountrySuccess(response.data.data.data));
  } catch (error) {
    yield put(countriesFail(error));
  }
}

export function* deleteCountrySaga(action) {
  try {
    const response = yield axios.delete(`${COUNTRIES_API}${action.id}`);
    yield call(getCountriesSaga);
  } catch (error) {
    yield put(countriesFail(error));
  }
}
