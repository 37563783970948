import * as actionTypes from "../../actions/languageSelector/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  responseLanguages: [],
  response: false,
};
const languagesStart = (state, action) => {
  return updateObject(state, { error: [], responseLanguages: [], response: false });
};

const languagesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getLanguages = (state, action) => {
  return updateObject(state, {
    responseLanguages: action.response,
    response: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LANGUAGE_SELECTOR_START:
      return languagesStart(state, action);
    case actionTypes.LANGUAGE_SELECTOR_FAIL:
      return languagesFail(state, action);
    case actionTypes.LANGUAGE_SELECTOR_CURRENT_LANGUAGE_SUCCESS:
      return getLanguages(state, action);
    default:
      return state;
  }
};

export default reducer;
