import * as actionTypes from "./actionTypes";

export const userInvestmentStart = () => {
  return {
    type: actionTypes.USER_INVESTMENT_START,
  };
};

export const getUserInvestments = (response) => {
  return {
    type: actionTypes.GET_USER_INVESTMENT,
    response,
  };
};

export const getUserInvestmentSuccess = (response) => {
  
  return {
    type: actionTypes.GET_USER_INVESTMENTS_SUCCESS,
    response,
  };
};

export const userInvestmentFail = (error) => {
  return {
    type: actionTypes.GET_USER_INVESTMENTS_FAIL,
    error,
  };
};
