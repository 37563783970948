import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  admins: [],
  message: null,
  error: [],
  loading: false,
  roleList: [],
  admin: null,
  updatedAdmin: [],
  deleteResponse: null,
  currentAdmin: [],
  batchUpdate: [],
  isUpdated: false,
  isCreated: false,
  changePasswordSuccessMsg: null,
  changePasswordErrorMsg: null,
  changePasswordLoader: false
};

const getAllAdminsStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, message: null, admin: null, isUpdated: false, isCreated: false });
};

const getAllAdminsSuccess = (state, action) => {
  return updateObject(state, {
    admins: action.response,
    loading: false,
  });
};

const getAllAdminsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getAllAdminRolesStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, admins: [], admin: [], isUpdated: false, isCreated: false });
};

const getAllAdminRolesSuccess = (state, action) => {
  return updateObject(state, {
    roleList: action.response,
    loading: false,
    admins: [],
  });
};

const getAllAdminRolesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: true,
  });
};

const createNewAdminStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, admins: [], isUpdated: false, isCreated: false });
};

const createNewAdminSuccess = (state, action) => {
  return updateObject(state, {
    message: action.response,
    loading: false,
    isCreated: true,
  });
};
const createNewAdminFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getAdminByIdStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, isCreated: false });
};

const getAdminByIdSuccess = (state, action) => {
  return updateObject(state, {
    admin: action.response,
    loading: false,
  });
};

const getAdminByIdFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const updateAdminByIdStart = (state, action) => {
  return updateObject(state, { error: null, loading: true, admins: [], admin: null, isCreated: false });
};

const updateAdminByIdSuccess = (state, action) => {
  return updateObject(state, {
    updatedAdmin: action.response,
    loading: false,
    isUpdated: true,
  });
};

const updateAdminByIdFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const deleteAdminByIdStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const deleteAdminByIdSuccess = (state, action) => {
  return updateObject(state, {
    deleteResponse: true,
    loading: false,
  });
};

const deleteAdminByIdFail = (state, action) => {
  return updateObject(state, {
    error: true,
    loading: false,
  });
};

const getCurrentAdmin = (state, action) => {
  return updateObject(state, {
    currentAdmin: action.response,
  });
};

const batchUpdateStart = (state, action) => {
  return updateObject(state, {
    batchUpdate: [],
    loading: true,
  });
};

const batchUpdateSuccess = (state, action) => {
  return updateObject(state, {
    batchUpdate: action.response,
    loading: false,
  });
};

const batchupdateFail = (state, action) => {
  return updateObject(state, {
    batchUpdate: action.error,
    loading: false,
  });
};

const changePasswordStart = (state, action) => {
  return updateObject(state, {
    changePasswordLoader: action.clear ? false : true,
    changePasswordSuccessMsg: null,
    changePasswordErrorMsg: null
  });
};
const changePasswordSuccess = (state, action) => {
  return updateObject(state, {
    changePasswordSuccessMsg: action.response,
    changePasswordLoader: false
  });
};
const changePasswordFail = (state, action) => {
  return updateObject(state, {
    changePasswordErrorMsg: action.error,
    changePasswordLoader: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADMINS_START:
      return getAllAdminsStart(state, action);
    case actionTypes.GET_ADMINS_SUCCESS:
      return getAllAdminsSuccess(state, action);
    case actionTypes.GET_ADMINS_FAIL:
      return getAllAdminsFail(state, action);
    case actionTypes.GET_ADMINS_ROLES_START:
      return getAllAdminRolesStart(state, action);
    case actionTypes.GET_ADMINS_ROLES_SUCCESS:
      return getAllAdminRolesSuccess(state, action);
    case actionTypes.GET_ADMINS_ROLES_FAIL:
      return getAllAdminRolesFail(state, action);
    case actionTypes.CREATE_NEW_ADMIN_START:
      return createNewAdminStart(state, action);
    case actionTypes.CREATE_NEW_ADMIN_SUCCESS:
      return createNewAdminSuccess(state, action);
    case actionTypes.CREATE_NEW_ADMIN_FAIL:
      return createNewAdminFail(state, action);
    case actionTypes.GET_ADMIN_BY_ID_START:
      return getAdminByIdStart(state, action);
    case actionTypes.GET_ADMIN_BY_ID_SUCCESS:
      return getAdminByIdSuccess(state, action);
    case actionTypes.GET_ADMIN_BY_ID_FAIL:
      return getAdminByIdFail(state, action);
    case actionTypes.UPDATE_ADMIN_BY_ID_START:
      return updateAdminByIdStart(state, action);
    case actionTypes.UPDATE_ADMIN_BY_ID_SUCCESS:
      return updateAdminByIdSuccess(state, action);
    case actionTypes.UPDATE_ADMIN_BY_ID_FAIL:
      return updateAdminByIdFail(state, action);
    case actionTypes.DELETE_ADMIN_START:
      return deleteAdminByIdStart(state, action);
    case actionTypes.DELETE_ADMIN_SUCCESS:
      return deleteAdminByIdSuccess(state, action);
    case actionTypes.DELETE_ADMIN_FAIL:
      return deleteAdminByIdFail(state, action);
    case actionTypes.GET_CURRENT_ADMIN_SUCCESS:
      return getCurrentAdmin(state, action);
    case actionTypes.BATCH_ADMIN_UPDATE_START:
      return batchUpdateStart(state, action);
    case actionTypes.BATCH_ADMIN_UPDATE_SUCCESS:
      return batchUpdateSuccess(state, action);
    case actionTypes.BATCH_ADMIN_UPDATE_FAIL:
      return batchupdateFail(state, action);
    case actionTypes.CHANGE_PASSWORD_START:
      return changePasswordStart(state, action);
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return changePasswordSuccess(state, action);
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return changePasswordFail(state, action);
    default:
      return state;
  }
};

export default reducer;
