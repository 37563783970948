import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  loading: false,
  errors: [],
  frontEndUsers: [],
  frontEndUser: [],
  insertSuccess: false,
  updateSuccess: false,
  userTypes: [],
};
const frontEndUsersStart = (state, action) => {
  return updateObject(state, {
    erros: null,
    loading: true,
    insertSuccess: false,
    updateSuccess: false,
    frontEndUser: [],
    error: false,
  });
};
const frontEndUsersFail = (state, action) => {
  return updateObject(state, {
    errors: action.error,
    error: true,
  });
};

const frontEndUsers = (state, action) => {
  return updateObject(state, {
    frontEndUsers: action.response,
    loading: false,
    insertSuccess: false,
    updateSuccess: false,
  });
};

const insertFrontEndUser = (state, action) => {
  return updateObject(state, {
    insertSuccess: true,
  });
};

const updateFrontEndUser = (state, action) => {
  return updateObject(state, {
    updateSuccess: true,
  });
};

const getFrontEndUser = (state, action) => {
  return updateObject(state, {
    frontEndUser: action.response,
  });
};

const bulkUpdateUser = (state, action) => {
  return updateObject(state, {
    bulkUpdateSuccess: true,
    loading: false,
  });
};

const getUserTypes = (state, action) => {
  return updateObject(state, {
    userTypes: action.typesData,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FRONT_END_USERS_START:
      return frontEndUsersStart(state, action);
    case actionTypes.FRONT_END_USERS_FAIL:
      return frontEndUsersFail(state, action);
    case actionTypes.GET_ALL_FRONT_END_USERS_SUCCESS:
      return frontEndUsers(state, action);
    case actionTypes.CREATE_FRONT_END_USER_SUCCESS:
      return insertFrontEndUser(state, action);
    case actionTypes.GET_FRONT_END_USER_SUCCESS:
      return getFrontEndUser(state, action);
    case actionTypes.UPDATE_FRONT_END_USER_SUCCESS:
      return updateFrontEndUser(state, action);
    case actionTypes.BULK_UPDATE_FRONT_END_USER_SUCCESS:
      return bulkUpdateUser(state, action);
    case actionTypes.USER_TYPE_SUCCESS:
      return getUserTypes(state, action);
    default:
      return state;
  }
};

export default reducer;
