import * as actionTypes from "../../actions/mangopay/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  loadingMangopayTransactions: false,
  isSpinning: false,
  mangopayWalletTransactions: [],
  mangopayWalletDetail: [],
};

const getMangopayWalletTransactionsSuccess = (state, action) => {
  return updateObject(state, {
    mangopayWalletTransactions: action.response,
    loadingMangopayTransactions: false,
    isSpinning: false,
    error: null,
  });
};

const getMangopayWalletDetailSuccess = (state, action) => {
  return updateObject(state, {
    mangopayWalletDetail: action.response,
    loadingMangopayTransactions: false,
    isSpinning: false,
    error: null,
  });
};

const mangopayWalletFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingMangopayTransactions: false,
    isSpinning: false,
  });
};

const mangopayWalletStart = (state, action) => {
  return updateObject(state, {
    mangopayWalletTransactions: [],
    mangopayWalletDetail: [],
    error: null,
    loadingMangopayTransactions: action?.pageLimit === 1 ? true : false,
    isSpinning: action?.pageLimit === 1 ? false : true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MANGOPAY_WALLET_TRANSACTIONS_SUCCESS:
      return getMangopayWalletTransactionsSuccess(state, action);
    case actionTypes.GET_MANGOPAY_WALLET_DETAIL_SUCCESS:
      return getMangopayWalletDetailSuccess(state, action);
    case actionTypes.MANGOPAY_WALLET_FAIL:
      return mangopayWalletFail(state, action);
    case actionTypes.MANGOPAY_WALLET_START:
      return mangopayWalletStart(state, action);

    default:
      return state;
  }
};

export default reducer;
