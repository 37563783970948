import * as actionTypes from "./actionTypes";

export const languageSelectorStart = () => {
  return {
    type: actionTypes.LANGUAGE_SELECTOR_START,
  };
};

export const languageSelectorFail = (error) => {
  return {
    type: actionTypes.LANGUAGE_SELECTOR_FAIL,
    error,
  };
};

export const getLanguage = () => {
  return {
    type: actionTypes.LANGUAGE_SELECTOR_CURRENT_LANGUAGE,
  };
};

export const getLanguageSuccess = (response) => {
  return {
    type: actionTypes.LANGUAGE_SELECTOR_CURRENT_LANGUAGE_SUCCESS,
    response,
  };
};
