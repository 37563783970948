import * as actionTypes from "../../actions/spamSettings/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  spamSetting: [],
  error: false,
  created: false,
  updated: false,
  spammers: [],
  spammer: [],
};

export const spamStart = (state, action) => {
  return updateObject(state, {
    spamSetting: [],
    error: false,
    created: false,
    updated: false,
    spammer: [],
  });
};

export const getSpammerSetting = (state, action) => {
  return updateObject(state, {
    spamSetting: action.response,
  });
};

export const createSpammer = (state, action) => {
  return updateObject(state, {
    created: true,
  });
};

export const updateSpammer = (state, action) => {
  return updateObject(state, {
    updated: true,
  });
};

export const getSpammerList = (state, action) => {
  return updateObject(state, {
    spammers: action.response,
  });
};

export const getSpammer = (state, action) => {
  return updateObject(state, {
    spammer: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SPAM_START:
      return spamStart(state, action);
    case actionTypes.GET_SPAM_SETTING_SUCCESS:
      return getSpammerSetting(state, action);
    case actionTypes.SPAM_SUCCESS:
    case actionTypes.CREATE_SPAMMER_SUCCESS:
      return createSpammer(state, action);
    case actionTypes.SPAM_UPDATE_SUCCESS:
      return updateSpammer(state, action);
    case actionTypes.GET_SPAMMERS_SUCCESS:
      return getSpammerList(state, action);
    case actionTypes.GET_SPAMMER_SUCCESS:
      return getSpammer(state, action);
    default:
      return state;
  }
};

export default reducer;
