/* eslint-disable no-unused-vars */
import { call, put, delay } from "redux-saga/effects";
import { GET_ALL_CATEGORY_API, CREATE_CATEGORY_API, GET_CATEGORY_BY_ID_API, UPDATE_CATEGORY_BY_ID_API, DELETE_CATEGORY_BY_ID_API, GET_LANGUAGES, CATEGORY_BULK_DELETE, CATEGORY_BULK_UPDATE } from "../actions/apiCollections";
import axios from "../../config/AxiosConfig";
import { categoriesCommonStart, getAllCategorySuccess, commonCategoriesFail, createCategorySuccess, getCategorySuccess, getLanguagesSuccess, getCategoryLanguageCurrentId, bulkUpdateCategoriesSuccess } from "../actions/index";

export function* getAllCategoriesSaga(action) {
  yield put(categoriesCommonStart());
  try {
    const lang = action.language ? action.language : "";
    const search = action.search ? action.search : "";
    const limit  = action.pageLimit !== "all" ? 10 * action.pageLimit : "all";
    const response = yield axios.get(`${GET_ALL_CATEGORY_API}`, {
      params: {
        language: lang,
        limit,
        search
      }
    });
    yield put(getAllCategorySuccess(response.data.data.data));
    if (action.language !== undefined) {
      yield put(getCategoryLanguageCurrentId(action.language));
    }
  } catch (error) {
    yield put(commonCategoriesFail(error.response));
  }
}

export function* createCategorySaga(action) {
  try {
    const response = yield axios.post(`${CREATE_CATEGORY_API}?lng=en`, action.categoryData);
    yield put(createCategorySuccess(response.data.data.data));
  } catch (error) {
    yield put(commonCategoriesFail(error.response.data.message));
  }
}

export function* getCategoryByIdSaga(action) {
  try {
    const response = yield axios.get(`${GET_CATEGORY_BY_ID_API}${action.id}`);
    yield put(getCategorySuccess(response.data.data.data));
  } catch (error) {
    yield put(commonCategoriesFail(error.response));
  }
}

export function* updateCategoryByIdSaga(action) {
  let id = new FormData();
  id = action.categoryData;
  id = id.get("id");

  try {
    const response = yield axios.patch(`${UPDATE_CATEGORY_BY_ID_API}${id}`, action.categoryData);
    yield put(createCategorySuccess(response.data.data.data));
  } catch (error) {
    yield put(commonCategoriesFail(error.response));
  }
}

export function* deleteCategoryByIdSaga(action) {
  try {
    yield axios.delete(`${DELETE_CATEGORY_BY_ID_API}${action.id}`);
    yield call(getAllCategoriesSaga, action);
  } catch (error) {
    yield put(commonCategoriesFail(error));
  }
}

export function* categoriesBulkUpdateSaga(action) {
  try {
    const response = yield axios.post(CATEGORY_BULK_UPDATE, action.obj);
    yield put(bulkUpdateCategoriesSuccess(response.data.data.data));
    yield call(getAllCategoriesSaga, action);
  } catch (error) {
    yield put(commonCategoriesFail(error));
  }
}

export function* categoriesBulkDelete(action) {
  
  try {
    yield axios.post(CATEGORY_BULK_DELETE, action.obj);
    yield call(getAllCategoriesSaga, action);
  } catch (error) {
    yield put(commonCategoriesFail(error));
  }
}

export function* getLanguagesSuccessSaga() {
  try {
    const response = yield axios.get(`${GET_LANGUAGES}?fields=id,name,status&sort=name`);
    yield put(getLanguagesSuccess(response.data.data.data));
    yield put(getCategoryLanguageCurrentId(response.data.data.data[0].id));
  } catch (error) {
    yield put(commonCategoriesFail(error));
  }
}
