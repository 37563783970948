/* eslint-disable no-unused-vars */
import axios from "../../../config/AxiosConfig";
import { put, call } from "redux-saga/effects";
import {
  TRANSACTIONS_API,
  ACTIVE_CAMPAIGN_LIST_API,
  INVESTOR_LIST_API,
} from "../../actions/apiCollections";
import * as actions from "../../actions/transactions/transactionsActions";

export function* getTransactions(action) {
  yield put(actions.transactionsStart());

  try {
    const response = yield axios.get(`${TRANSACTIONS_API}?lng=en`, {
      params: { limit: 10 * action.response },
    });
    yield put(actions.getTransactionsSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.transactionFail(error));
  }
}

export function* getTransaction(action) {
  yield put(actions.transactionsStart());
  try {
    const response = yield axios.get(
      `${TRANSACTIONS_API}${action.campaignId}?lng=en`
    );
    yield put(actions.getTransactionSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.transactionFail(error));
  }
}

export function* updateTransactionSaga(action) {
  console.log(action.response);
  yield put(actions.transactionsStart());
  try {
    const response = yield axios.patch(
      `${TRANSACTIONS_API}${action.response.tranId}?lng=en`,
      action.response
    );
    const obj = {
      response: 1,
    };
    yield call(getTransactions, obj);
  } catch (error) {
    yield put(actions.transactionFail(error));
  }
}

export function* getSearchTransactionsSaga(action) {
  yield put(actions.transactionsStart());
  try {
    const response = yield axios.get(`${TRANSACTIONS_API}?lng=en`, {
      params: {
        ...(action.response.campaign !== "" && {
          campaign: action.response.campaign,
        }),
        ...(action.response.investor !== "" && {
          user: action.response.investor,
        }),
        ...(action.response.minamount !== "" && {
          minAmount: action.response.minamount,
        }),
        ...(action.response.maxamount !== "" && {
          maxAmount: action.response.maxamount,
        }),
        ...(action.response.transaction !== "" && {
          transaction: action.response.transaction,
        }),
        ...(action.response.preapprovalStatus !== "" && {
          preapprovalStatus: action.response.preapprovalStatus,
        }),
        ...(action.limitNo !== "" && {
          limit: 10 * action.limitNo,
        }),
      },
    });
    yield put(actions.getTransactionsSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.transactionFail(error));
  }
}

export function* getActiveCamapignListSaga(action) {
  yield put(actions.transactionsStart());
  try {
    const response = yield axios.get(ACTIVE_CAMPAIGN_LIST_API);
    yield put(actions.getActiveCamapignListSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.transactionFail(error));
  }
}

export function* getInvestorListSaga(action) {
  yield put(actions.transactionsStart());
  try {
    const response = yield axios.get(INVESTOR_LIST_API);
    yield put(actions.getInvestorListSuccess(response.data.data.data));
  } catch (error) {
    yield put(actions.transactionFail(error));
  }
}

export function* addTransactionSaga(action) {
  yield put(actions.transactionsStart());
  try {
    const response = yield axios.post(
      `${TRANSACTIONS_API}?lng=en`,
      action.transactionData
    );
    yield put(actions.addTransactionSuccess(response.data.data.data));
    const obj = {
      response: 1,
    };
    yield call(getTransactions, obj);
  } catch (error) {
    yield put(actions.transactionFail(error?.response?.data?.message));
  }
}
