/**
 * @description a method which returns Vimeo Video Thumbnail on a valid Vimeo video ID
 * @param {String} url
 * @example getVimeoThumbnail("https://vimeo.com/29951514")
 */

function getVimeoThumbnail(url) {
  var id = false;
  var request = new XMLHttpRequest();
  request.open("GET", "https://vimeo.com/api/oembed.json?url=" + url, false);
  request.onload = function () {
    if (request.status >= 200 && request.status < 400) {
      var response = JSON.parse(request.responseText);
      if (response.video_id) {
        id = response.thumbnail_url;
      }
    }
  };
  request.send();
  return id;
}

/**
 * @description a simple method to get `YouTube` or `Vimeo` thumbnail
 * @function getYouTubeThumbnail
 * @function getVimeoThumbnail
 * @param {String} url
 * @returns {String} `image`
 */

export const getYouTubeThumbnail = (url) => {
  var video, results;
  if (url === undefined || url === null) {
    return "";
  }
  if (url.indexOf("youtube.com") > -1) {
    results = url.match("[\\?&]v=([^&#]*)");
    video = results === null ? url : results[1];
    return "http://img.youtube.com/vi/" + video + "/0.jpg";
  }
  if (url.indexOf("vimeo.com") > -1) {
    const thumbnail = getVimeoThumbnail(url);
    return thumbnail;
  }
};
