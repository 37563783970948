import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import Select from "react-select";
import { useIntl } from "react-intl";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import * as actions from "../../../store/actions/index";
import { getDemoMode } from "../../../helpers";
import axios from "../../../config/AxiosConfig";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  CLEAR_CACHE_API,
  CLEAN_DATABASE_API,
} from "../../../store/actions/apiCollections";
import { authCheckState } from "../../../store/actions/index";

export const TestingEnvironment = () => {
  const demoMode = getDemoMode();
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const maintainanceModeList = [
    {
      value: "yes",
      label: intl.formatMessage({ id: "GLOBAL.YES" }),
    },
    {
      value: "no",
      label: intl.formatMessage({ id: "GLOBAL.NO" }),
    },
  ];

  useEffect(() => {
    dispatch(actions.getGeneralSettings());
    dispatch(authCheckState());
  }, [dispatch]);

  const { generalSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    const getGeneralSettingsDetail = {
      id: generalSettings?.id,
      maintainanceMode: generalSettings?.maintainanceMode,
      exceptIP: generalSettings?.exceptIP,
    };

    reset({ ...getGeneralSettingsDetail });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generalSettings]);

  const MySwal = withReactContent(Swal);
  const alertBox = (text, icon) => {
    MySwal.fire({
      title: text,
      icon: icon,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result["isConfirmed"]) {
        dispatch(actions.getGeneralSettings());
      }
    });
  };

  const onCleanDataOnly = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "maintenanceMode.clean.confirm" }),
      icon: "warning",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(CLEAN_DATABASE_API, { params: { lng: "en" } })
          .then((response) => {
            Swal.fire(response.data.data.data, "", "success");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const onClearCache = () => {
    MySwal.fire({
      title: intl.formatMessage({ id: "maintenanceMode.clearCache.confirm" }),
      icon: "warning",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(CLEAR_CACHE_API, { params: { lng: "en" } })
          .then((response) => {
            Swal.fire(response.data.data.data, "", "success");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const onSubmit = (data) => {
    let generalSettingData = new FormData();
    generalSettingData.append("id", data.id);
    generalSettingData.append("maintainanceMode", data.maintainanceMode);
    generalSettingData.append("exceptIP", data.exceptIP);
    dispatch(actions.updateGeneralSettings(generalSettingData));
    alertBox(intl.formatMessage({ id: "MAINTENANCEMODE.SUCESS" }), "success");
  };

  return (
    <>
      <Card>
        <CardHeader
          title={intl.formatMessage({ id: "maintenanceMode.title" })}
        />
        <CardBody>
          <Row className="justify-content-md-center">
            <Col md={8}>
              <Form
                className="form form-label-right"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Form.Control type="hidden" name="id" {...register("id")} />
                <div className="form-group">
                  <label>
                    {intl.formatMessage({
                      id: "MAINTENANCEMODE",
                    })}
                    <span className="text-important">*</span>{" "}
                  </label>
                  <Controller
                    name={"maintainanceMode"}
                    control={control}
                    rules={{
                      required: intl.formatMessage({
                        id: "COMMON.FIELD.REQ",
                      }),
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          placeholder={"Select Status"}
                          classNamePrefix="react-select"
                          className={classNames("react-select-container", {
                            "is-invalid": errors.maintainanceMode,
                          })}
                          options={maintainanceModeList}
                          value={maintainanceModeList.find(
                            (c) => c.value === value
                          )}
                          onChange={(val) => onChange(val.value)}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="maintainanceMode"
                    render={({ message }) => (
                      <div className="invalid-feedback">{message}</div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>
                    {intl.formatMessage({
                      id: "IPADDRESS",
                    })}
                    <span className="text-important">*</span>{" "}
                  </label>
                  <Form.Control
                    name="exceptIP"
                    className={classNames("form-control", {
                      "is-invalid": errors.exceptIP,
                    })}
                    {...register("exceptIP", {
                      required: intl.formatMessage({ id: "COMMON.FIELD.REQ" }),
                      pattern: {
                        value:
                          /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/,
                        message: intl.formatMessage({
                          id: "IPADDRESS.ERROR",
                        }),
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="exceptIP"
                    render={({ message }) => (
                      <div className="invalid-feedback">{message}</div>
                    )}
                  />
                </div>
                {demoMode === "false" ? (
                  <>
                    <button type="submit" className="btn btn-primary mr-2">
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger ml-3 mr-3"
                      onClick={onCleanDataOnly}
                    >
                      <SVG
                        title=" "
                        className="mr-2"
                        src={toAbsoluteUrl("/media/svg/icons/Tools/Brush.svg")}
                      />
                      Clean Data Only
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onClearCache}
                    >
                      <SVG
                        title=" "
                        className="mr-2"
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                      Clear Cache
                    </button>
                  </>
                ) : null}
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
