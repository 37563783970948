import * as actionTypes from "../../actions/revenue/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  revenue: null,
  success: false,
};

const revenueStart = (state, action) => {
  return updateObject(state, { error: null, success: false });
};

const revenueFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const commonRevenueSuccess = (state, action) => {
  return updateObject(state, {
    success: true,
  });
};

const getRevenue = (state, action) => {
  return updateObject(state, {
    revenue: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REVENUE_START:
      return revenueStart(state, action);
    case actionTypes.REVENUE_FAIL:
      return revenueFail(state, action);
    case actionTypes.GET_REVENUE_SUCCESS:
      return getRevenue(state, action);
    case actionTypes.SUCCESS_COMMON:
      return commonRevenueSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
