import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  dashboardFail,
  getDashboardCountSuccess,
  getDashboardPendingCampaignsSuccess,
  getDashboardCampaignsRevenuesSuccess,
  getDashboardCampaignTotalInvestmentSuccess,
  getDashboardCampaignTotalRepaymentSuccess,
} from "../../actions/dashboard/dashboardActions";
import {
  DASHBOARD_API,
  CAMPAIGN_LIST_API,
  CAMPAIGNS_REVENUES_TOTAL_API,
  CAMPAIGNS_INVESTMENTS_TOTAL_API,
  CAMPAIGNS_REPAYMENT_TOTAL_API,
} from "../../actions/apiCollections";

export function* getDashboardCountSaga() {
  try {
    const response = yield axios.get(DASHBOARD_API);
    yield put(getDashboardCountSuccess(response.data.data.data));
  } catch (error) {
    yield put(dashboardFail(error));
  }
}

export function* getDashboardPendingCampaignsSaga(action) {
  try {
    const response = yield axios.get(CAMPAIGN_LIST_API + "admin", {
      params: {
        status: 1,
        limit: 10,
      },
    });
    yield put(getDashboardPendingCampaignsSuccess(response.data.data.data));
  } catch (error) {
    yield put(dashboardFail(error));
  }
}

export function* getDashboardCampaignsRevenuesSaga(action) {
  try {
    let currencyId = action.currencyId !== undefined ? `/${action.currencyId}` : "";
    const response = yield axios.get(`${CAMPAIGNS_REVENUES_TOTAL_API}${currencyId}`, {
      params: {
        lng: "en",
      },
    });
    yield put(getDashboardCampaignsRevenuesSuccess(response.data.data.data));
  } catch (error) {
    yield put(dashboardFail(error));
  }
}

export function* getDashboardCampaignTotalInvestmentSaga(action) {
  try {
    let currencyId = action.currencyId !== undefined ? `/${action.currencyId}` : "";
    const response = yield axios.get(`${CAMPAIGNS_INVESTMENTS_TOTAL_API}${currencyId}`, {
      params: {
        lng: "en",
      },
    });
    yield put(
      getDashboardCampaignTotalInvestmentSuccess(response.data.data.data)
    );
  } catch (error) {
    yield put(dashboardFail(error));
  }
}

export function* getDashboardCampaignTotalRepaymentSaga(action) {
  try {
    let currencyId = action.currencyId !== undefined ? `/${action.currencyId}` : "";
    const response = yield axios.get(`${CAMPAIGNS_REPAYMENT_TOTAL_API}${currencyId}`, {
      params: {
        lng: "en",
      },
    });
    yield put(
      getDashboardCampaignTotalRepaymentSuccess(response.data.data.data)
    );
  } catch (error) {
    yield put(dashboardFail(error));
  }
}
