import { updateObject } from "../../utility/utility";
import * as actionTypes from "../../actions/activity/actionTypes";

const initialState = {
  activities: [],
  error: null,
  displayLoadMore: false
};

const activityStart = (state, action) => {
  return updateObject(state, {
    error: null,
  });
};

const activityError = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const getActivities = (state, action) => {
  return updateObject(state, {
    activities: action.response.docs,
    displayLoadMore: action.response.displayLoadMore
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTIVITY_START:
      return activityStart(state, action);
    case actionTypes.ACTIVITY_FAIL:
      return activityError(state, action);
    case actionTypes.GET_ACTIVITIES_SUCCESS:
      return getActivities(state, action);

    default:
      return state;
  }
};

export default reducer;
