import * as actionTypes from "./actiontTypes";

export const getInvestorsStart = () => {
  return {
    type: actionTypes.GET_INVESTORS_START,
  };
};

export const getInvestorsFail = (error) => {
  return {
    type: actionTypes.GET_INVESTORS_FAIL,
    error,
  };
};

export const getInvestors = (search, pageLimit) => {
  return {
    type: actionTypes.GET_INVESTORS,
    search,
    pageLimit,
  };
};

export const getInvestorsSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTORS_SUCCESS,
    response,
  };
};

export const getInvestor = (id) => {
  return {
    type: actionTypes.GET_INVESTOR,
    id,
  };
};

export const getInvestorSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_SUCCESS,
    response,
  };
};

export const accreditationAction = (accData) => {
  return {
    type: actionTypes.ACCREDITATION_ACTION,
    accData,
  };
};

export const accreditationActionResponse = (response) => {
  return {
    type: actionTypes.ACCREDITATION_ACTION_RESPONSE,
    response,
  };
};
