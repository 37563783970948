/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { getDashboardCampaignTotalRepayment } from "../../../../store/actions/dashboard/dashboardActions";
import { formatCurrency } from "../../../../helpers/numberFormat";

export function StatsWidget12({ className }) {
  const dispatch = useDispatch();
  const [monthsRePayments, setMonthsRePayments] = useState([]);
  const [monthlyRePaymentsTotal, setMonthlyRePaymentsTotal] = useState([]);
  const [currentRePaymentCurrency, setCurrentRePaymentCurrency] = useState("");

  const { totalCampaignRePayment, dashboardSelectedCurrency } = useSelector((state) => state.dashboard);
  const { generalSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getDashboardCampaignTotalRepayment(dashboardSelectedCurrency));
  }, [dispatch, dashboardSelectedCurrency]);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
      colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      colorsThemeLightPrimary: objectPath.get(uiService.config, "js.colors.theme.light.primary"),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    if (totalCampaignRePayment) {
      setCurrentRePaymentCurrency(totalCampaignRePayment?.currentCurrencyData?.symbol);
      const monthsCampaignRePayment = totalCampaignRePayment?.RepaymentData?.map(function (item) {
        return item["_id"];
      });
      setMonthsRePayments(monthsCampaignRePayment);
      const campaignRePaymentsTotal = totalCampaignRePayment?.RepaymentData?.map(function (item) {
        return item["totalAmount"];
      });
      setMonthlyRePaymentsTotal(campaignRePaymentsTotal);
    }
  }, [totalCampaignRePayment]);

  useEffect(() => {
    if (layoutProps && monthsRePayments && monthlyRePaymentsTotal && currentRePaymentCurrency) {
      const element = document.getElementById("kt_stats_widget_12_chart");
      if (!element) {
        return;
      }
      const options = getChartOption(layoutProps, monthsRePayments, monthlyRePaymentsTotal, currentRePaymentCurrency);
      const chartnewUsers = new ApexCharts(element, options);
      chartnewUsers.render();
      return function cleanUp() {
        chartnewUsers.destroy();
      };
    }
  }, [layoutProps, monthsRePayments, monthlyRePaymentsTotal, currentRePaymentCurrency]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            <a href={undefined} className="text-dark-75 text-hover-primary font-weight-bolder font-size-h5">
              Re-Payments
            </a>
            <span className="text-muted font-weight-bold mt-1">
              Total Re-Payments:{" "}
              {formatCurrency(
                totalCampaignRePayment?.totalRepayment ?? 0,
                generalSettings?.currencySymbolSide,
                totalCampaignRePayment?.currentCurrencyData?.symbol,
                totalCampaignRePayment?.currentCurrencyData?.code,
                generalSettings?.decimalPoints
              )}
            </span>
          </div>
        </div>
        <div id="kt_stats_widget_12_chart" className="card-rounded-bottom" style={{ height: "150px" }}></div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, monthsRePayments, monthlyRePaymentsTotal, currentRePaymentCurrency) {
  var options = {
    series: [
      {
        name: "Total",
        data: monthlyRePaymentsTotal,
      },
    ],
    chart: {
      type: "area",
      height: 150,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [layoutProps.colorsThemeBasePrimary],
    },
    xaxis: {
      categories: monthsRePayments,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      min: 0,
      // max: 55,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return `${currentRePaymentCurrency}` + val.toLocaleString();
        },
      },
    },
    colors: [layoutProps.colorsThemeLightPrimary],
    markers: {
      colors: [layoutProps.colorsThemeLightPrimary],
      strokeColor: [layoutProps.colorsThemeBasePrimary],
      strokeWidth: 3,
    },
  };
  return options;
}
