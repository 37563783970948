// eslint-disable-next-line no-use-before-define
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useSelector, useDispatch } from "react-redux";
import { Form, Modal } from "react-bootstrap";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getBaseImageURL } from "../../../../../helpers";
import {
  changePassword,
  changePasswordStart,
} from "../../../../../store/actions";

export function QuickUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const MySwal = withReactContent(Swal);
  const {
    currentAdmin,
    changePasswordSuccessMsg,
    changePasswordErrorMsg,
    changePasswordLoader,
  } = useSelector((state) => state.adminAuth);
  const globalImagePath = useRef(getBaseImageURL());
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (changePasswordSuccessMsg) {
      MySwal.fire({
        title: intl.formatMessage({ id: "CHANGE.PASSWORD.SUCCESS" }),
        html: (
          <div>
            <p>{intl.formatMessage({ id: "CHANGE.PASSWORD.ALERT" })}</p>
            <p className="text-warning">
              {intl.formatMessage({ id: "CHANGE.PASSWORD.ALERT.BRIEF" })}
            </p>
          </div>
        ),
        icon: "warning",
        confirmButtonText: intl.formatMessage({ id: "GLOBAL.OKAY" }),
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result["isConfirmed"]) {
          dispatch(changePasswordStart(true));
          history.push("/logout");
        }
      });
    }
  }, [dispatch, changePasswordSuccessMsg]);

  useEffect(() => {
    if (changePasswordErrorMsg) {
      MySwal.fire({
        title: intl.formatMessage({ id: "ERROR" }),
        text: changePasswordErrorMsg?.message,
        icon: "error",
      });
      dispatch(changePasswordStart(true));
    }
  }, [dispatch, changePasswordErrorMsg]);

  const onChangePasswordSubmit = (data) => {
    dispatch(changePassword(data));
  };

  const handleClose = () => {
    setShow(false);
    reset();
  };

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">User Profile</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${
                  currentAdmin.photo
                    ? globalImagePath.current + currentAdmin.photo
                    : toAbsoluteUrl("/media/users/default.jpg")
                })`,
              }}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {`${currentAdmin.firstName} ${currentAdmin.lastName}`}
            </a>

            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {currentAdmin.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Sign out
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <div className="navi-link">
            <div className="symbol-label">
              <span className="svg-icon svg-icon-md svg-icon-warning">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}></SVG>
                <button
                  className="btn font-weight-bold"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Change Password
                </button>
              </span>
            </div>
          </div>
          {/* <Link to="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Notification2.svg")}></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Profile</div>
                <div className="text-muted">Account settings and more</div>
              </div>
            </div>
          </Link> */}

          {/* <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-bar1.svg")}></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a> */}

          {/* <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")}></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>

          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-opened.svg")}></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a>
        </div>

        <div className="separator separator-dashed my-7"></div>

        <div>
          <h5 className="mb-5">Recent Notifications</h5>

          <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-warning mr-5">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} className="svg-icon svg-icon-lg"></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a href="#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
                Another purpose persuade
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-warning py-1 font-size-lg">+28%</span>
          </div>

          <div className="d-flex align-items-center bg-light-success rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-success mr-5">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} className="svg-icon svg-icon-lg"></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a href="#" className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1">
                Would be to people
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-success py-1 font-size-lg">+50%</span>
          </div>

          <div className="d-flex align-items-center bg-light-danger rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-danger mr-5">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group-chat.svg")} className="svg-icon svg-icon-lg"></SVG>
            </span>
            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a href="#" className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">
                Purpose would be to persuade
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-danger py-1 font-size-lg">-27%</span>
          </div>

          <div className="d-flex align-items-center bg-light-info rounded p-5">
            <span className="svg-icon svg-icon-info mr-5">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Attachment2.svg")} className="svg-icon svg-icon-lg"></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a href="#" className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1">
                The best product
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-info py-1 font-size-lg">+8%</span>
          </div> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Form
          name="changePasswordForm"
          onSubmit={handleSubmit(onChangePasswordSubmit)}
        >
          <Modal.Body>
            <Form.Group className="mb-5">
              <Form.Label>
                {intl.formatMessage({ id: "CURRENT.PASSWORD" })}
              </Form.Label>
              <Form.Control
                name="passwordCurrent"
                id="passwordCurrent"
                type="password"
                placeholder={intl.formatMessage({ id: "CURRENT.PASSWORD" })}
                className={classNames("form-control", {
                  "is-invalid": errors.passwordCurrent,
                })}
                {...register("passwordCurrent", {
                  required: intl.formatMessage({ id: "CURRENT.PASSWORD.REQ" }),
                })}
              />
              <ErrorMessage
                errors={errors}
                name="passwordCurrent"
                render={({ message }) => (
                  <div className="invalid-feedback">{message}</div>
                )}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label>
                {intl.formatMessage({ id: "NEW.PASSWORD" })}
              </Form.Label>
              <Form.Control
                name="password"
                id="password"
                type="password"
                placeholder={intl.formatMessage({ id: "NEW.PASSWORD" })}
                className={classNames("form-control", {
                  "is-invalid": errors.password,
                })}
                {...register("password", {
                  required: intl.formatMessage({ id: "NEW.PASSWORD.REQ" }),
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: intl.formatMessage({ id: "yup.validPassword" }),
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <div className="invalid-feedback">{message}</div>
                )}
              />
            </Form.Group>
            <Form.Group className="mb-5">
              <Form.Label>
                {intl.formatMessage({ id: "CONFIRM.PASSWORD" })}
              </Form.Label>
              <Form.Control
                name="passwordConfirm"
                id="passwordConfirm"
                placeholder={intl.formatMessage({ id: "CONFIRM.PASSWORD" })}
                type="password"
                className={classNames("form-control", {
                  "is-invalid": errors.passwordConfirm,
                })}
                {...register("passwordConfirm", {
                  required: intl.formatMessage({ id: "CONFIRM.PASSWORD.REQ" }),
                  validate: (value) =>
                    value === getValues("password") ||
                    intl.formatMessage({ id: "CONFIRM.PASSWORD.ERROR" }),
                })}
              />
              <ErrorMessage
                errors={errors}
                name="passwordConfirm"
                render={({ message }) => (
                  <div className="invalid-feedback">{message}</div>
                )}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={changePasswordLoader}
            >
              {changePasswordLoader ? (
                <div class="spinner-border text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
