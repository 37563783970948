export const onPasteHelper = (event, setFieldValue, validateForm, fieldName) => {
  event.preventDefault();
  let { value, selectionStart, selectionEnd } = event.target;
  const pastedValue = event.clipboardData.getData("text/plain").trim();
  let pre = value.substring(0, selectionStart);
  let post = value.substring(selectionEnd, value.length);
  value = (pre + pastedValue + post).trim();
  setFieldValue(fieldName, value);
  validateForm();
};


