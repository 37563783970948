import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility/utility';
const initialState = {
  loading: false,
  errors: [],
  countries: [],
  activeCountries: [],
  language: null,
  countryInserted: false,
  countryUpdated: false,
  country: [],
};

const countriesStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    countryInserted: false,
    countryUpdated: false,
    country: [],
    activeCountries: [],
  });
};

const countriesFail = (state, action) => {
  return updateObject(state, {
    errors: action.error,
  });
};

const getCountries = (state, action) => {
  return updateObject(state, {
    countries: action.response,
  });
};

const activeCountriesCountries = (state, action) => {
  return updateObject(state, {
    activeCountries: action.response,
  });
};

const getCurrentLanguage = (state, action) => {
  return updateObject(state, {
    language: action.response,
  });
};

const countryInserted = (state, action) => {
  return updateObject(state, {
    countryInserted: true,
  });
};

const getCountry = (state, action) => {
  return updateObject(state, {
    country: action.response,
  });
};

const countryUpdated = (state, action) => {
  return updateObject(state, {
    countryUpdated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COUNTRIES_START:
      return countriesStart(state, action);
    case actionTypes.COUNTRIES_FAIL:
      return countriesFail(state, action);
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return getCountries(state, action);
    case actionTypes.GET_ENG_LANG_SUCCESS:
      return getCurrentLanguage(state, action);
    case actionTypes.CREATE_COUNTRY_SUCCESS:
      return countryInserted(state, action);
    case actionTypes.GET_COUNTRY_SUCCESS:
      return getCountry(state, action);
    case actionTypes.UPDATE_COUNTRY_SUCCESS:
      return countryUpdated(state, action);
    case actionTypes.GET_ACTIVE_COUNTRIES_SUCCESS:
      return activeCountriesCountries(state, action);
    default:
      return state;
  }
};

export default reducer;
