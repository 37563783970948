import { call, put } from "redux-saga/effects";
import {
  GET_ALL_ADMINS_API,
  GET_ALL_ADMIN_ROLES_API,
  CREATE_NEW_ADMIN_API,
  GET_ADMIN_BY_ID_API,
  UPDATE_ADMIN_BY_ID_API,
  DELETE_ADMIN_BY_ID_API,
  CURRENT_ADMIN,
  ADMIN_BULK_UPDATE,
  ADMIN_BULK_DELETE,
  UPDATE_PASSWORD_API
} from "../actions/apiCollections";
import {
  getAllAdminsStart,
  getAllAdminsSuccess,
  getAllAdminsFail,
  getAllAdminRolesSuccess,
  getAllAdminRolesFail,
  createNewAdminStart,
  createNewAdminSuccess,
  createNewAdminFail,
  getAdminByIdStart,
  getAdminByIdSuccess,
  getAdminByIdFail,
  updateAdminByIdStart,
  updateAdminByIdSuccess,
  updateAdminByIdFail,
  deleteAdminByIdStart,
  deleteAdminByIdSuccess,
  deleteAdminByIdFail,
  getCurrentAdminSuccess,
  batchUpdateAdminStart,
  batchAdminUpdateSuccess,
  batchAdminUpdateFail,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail
} from "../actions/index";
import axios from "../../config/AxiosConfig";

export function* getAllAdminsSaga(action) {
  try {
    const response = yield axios.get(GET_ALL_ADMINS_API, {
      params: {
        ...(action.search !== "" && {
          search: action.search,
        }),

        limit: 10 * action.pageLimit,
      },
    });
    yield put(getAllAdminsSuccess(response.data.data.data));
  } catch (error) {
    yield put(getAllAdminsFail(error));
  }
}

export function* getCurrentAdmin() {
  yield put(getAllAdminsStart());

  try {
    const response = yield axios.get(CURRENT_ADMIN, { params: { lng: "en" } });
    yield put(getCurrentAdminSuccess(response.data.data.data));
  } catch (error) {
    yield put(getAllAdminsFail(error));
  }
}

export function* getAllAdminRolesSaga() {
  try {
    const response = yield axios.get(GET_ALL_ADMIN_ROLES_API);
    yield put(getAllAdminRolesSuccess(response.data.data.data));
  } catch (error) {
    yield put(getAllAdminRolesFail(error));
  }
}

export function* createNewAdminSaga(action) {
  yield put(createNewAdminStart());
  try {
    const response = yield axios.post(CREATE_NEW_ADMIN_API, action.adminData, {
      params: {
        lng: "en",
      },
    });
    yield put(createNewAdminSuccess(response.data.data.data));
  } catch (error) {
    yield put(createNewAdminFail(error.response.data.errors));
  }
}

export function* getAdminByIdSaga(action) {
  yield put(getAdminByIdStart());
  try {
    const response = yield axios.get(`${GET_ADMIN_BY_ID_API}${action.id}`, {
      params: { lng: "en" },
    });
    yield put(getAdminByIdSuccess(response.data.data.data));
  } catch (error) {
    yield put(getAdminByIdFail(error));
  }
}

export function* updateAdminByIdSaga(action) {
  yield put(updateAdminByIdStart());
  try {
    let id = new FormData();
    id = action.adminData;
    id = id.get("id");
    const obj = {
      id,
    };
    const response = yield axios.patch(
      `${UPDATE_ADMIN_BY_ID_API}${id}`,
      action.adminData,
      {
        params: {
          lng: "en",
        },
      }
    );
    yield call(getAllAdminsSaga);
    yield call(getAdminByIdSaga, obj);
    yield put(updateAdminByIdSuccess(response.data.data.data));
  } catch (error) {
    yield put(updateAdminByIdFail(error));
  }
}

export function* deleteAdminByIdSaga(action) {
  yield put(deleteAdminByIdStart());
  try {
    const response = yield axios.delete(
      `${DELETE_ADMIN_BY_ID_API}${action.id}`,
      { params: { lng: "en" } }
    );
    yield put(deleteAdminByIdSuccess(response));
    action.pageLimit = 1;
    yield call(getAllAdminsSaga, action);
  } catch (error) {
    yield put(deleteAdminByIdFail(error));
  }
}

export function* bulkUpdate(action) {
  yield put(batchUpdateAdminStart());
  try {
    const response = yield axios.post(
      `${ADMIN_BULK_UPDATE}`,
      action.adminData,
      { params: { lng: "en" } }
    );
    yield put(batchAdminUpdateSuccess(response.data.data.data));
    yield call(getAllAdminsSaga, action);
  } catch (error) {
    yield put(batchAdminUpdateFail(error));
  }
}

export function* bulkDelete(action) {
  try {
    yield axios.post(`${ADMIN_BULK_DELETE}`, action.adminData, {
      params: { lng: "en" },
    });
    yield call(getAllAdminsSaga, action);
  } catch (error) {
    yield put(batchAdminUpdateFail(error));
  }
}

export function* changePasswordSaga(action) {
  yield put(changePasswordStart());
  try {
    const response = yield axios.patch(
      `${UPDATE_PASSWORD_API}`,
      action.credentials,
      { params: { lng: "en" } }
    );
    yield put(changePasswordSuccess(response.data));
  } catch (error) {
    yield put(changePasswordFail(error.response.data));
  }
}
