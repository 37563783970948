export const PAYMENT_GATEWAY_START = "PAYMENT_GATEWAY_START";
export const PAYMENT_GATEWAY_FAIL = "PAYMENT_GATEWAY_FAIL";
export const GET_PAYMENT_GATEWAY = "GET_PAYMENT_GATEWAY";
export const GET_PAYMENT_GATEWAY_SUCCESSFUL = "GET_PAYMENT_GATEWAY_SUCCESSFUL";
export const CREATE_PAYMENT_GATEWAY = "CREATE_PAYMENT_GATEWAY";
export const UPDATE_PAYMENT_GATEWAY = "UPDATE_PAYMENT_GATEWAY";
export const PAYMENT_GATEWAY_SUCCESS = "PAYMENT_GATEWAY_SUCCESS";

export const RECAPTCHA_START = "RECAPTCHA_START";
export const RECAPTCHA_FAIL = "RECAPTCHA_FAIL";
export const GET_RECAPTCHA = "GET_RECAPTHCA";
export const GET_RECAPTCHA_SUCCESS = "GET_RECAPTCHA_SUCCESS";
export const CREATE_RECAPTCHA = "CREATE_RECAPTCHA";
export const UPDATE_RECAPTCHA = "UPDATE_RECAPTCHA";
export const RECAPTCHA_SUCCESS = "RECAPTCHA_SUCCESS";
export const GET_PUBLIC_RECAPTCHA = "GET_PUBLIC_RECAPTHCA";
export const GET_PUBLIC_RECAPTCHA_SUCCESS = "GET_PUBLIC_RECAPTCHA_SUCCESS";

export const SOCIAL_START = "SOCIAL_START";
export const SOCIAL_FAIL = "SOCIAL_FAIL";
export const GET_SOCIAL = "GET_SOCIAL";
export const GET_SOCIAL_SUCCESS = "GET_SOCIAL_SUCCESS";
export const CREATE_SOCIAL = "CREATE_SOCIAL";
export const CREATE_SOCIAL_SUCCESS = "CREATE_SOCIAL_SUCCESS";
export const UPDATE_SOCIAL = "UPDATE_SOCIAL";
export const UPDATE_SOCIAL_SUCCESS = "UPDATE_SOCIAL_SUCCESS";
