/* eslint-disable no-unused-vars */
import {
  NOTIFICATION_TEMPLATES_API,
  NOTIFICATIONS_API,
} from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import { put, call } from "redux-saga/effects";
import * as actions from "../../actions/notificationTemplates/notificationTemplatesActions";

export function* getNotificationTemplatesSaga(action) {
  yield put(actions.notificationTemplatesStart());
  try {
    const response = yield axios.get(NOTIFICATION_TEMPLATES_API, {
      params: {
        ...(action.language !== "" && {
          language: action.language,
        }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(actions.getNotificationTemplatesSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getNotificationTemplateSaga(action) {
  try {
    const response = yield axios.get(
      `${NOTIFICATION_TEMPLATES_API}/${action.id}`,
      {
        params: {
          ...(action.language !== "" && {
            language: action.language,
          }),
        },
      }
    );
    yield put(actions.getNotificationTemplateSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* updateNotificationTemplateSaga(action) {
  try {
    yield axios.patch(
      `${NOTIFICATION_TEMPLATES_API}${action.notificationData.id}`,
      action.notificationData
    );

    const tData = {
      pageLimit: 1,
      language: action.notificationData.language,
    };
    yield call(getNotificationTemplatesSaga, tData);
  } catch (error) {
    console.log(error);
  }
}

export function* getMarkReadNotificationsSaga() {
  try {
    yield axios.post(NOTIFICATIONS_API, {
      read: 1,
    });
    const tData = {
      limit: 1,
    };
    yield call(getNotificationsSaga, tData);
  } catch (error) {
    console.log(error);
  }
}

export function* getNotificationsSaga(action) {
  try {
    const response = yield axios.get(NOTIFICATIONS_API, {
      params: { limit: 10 * action.limit },
    });
    yield put(actions.getNotificationsSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}
