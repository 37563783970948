import * as actionTypes from "./actionTypes";

export const newslettersStart = () => {
  return {
    type: actionTypes.NEWSLETTER_START,
  };
};

export const getNewsletters = (response) => {
  return {
    type: actionTypes.GET_NEWSLETTERS,
    response,
  };
};

export const getNewslettersSuccess = (response) => {
  return {
    type: actionTypes.GET_NEWSLETTERS_SUCCESS,
    response,
  };
};

export const getNewsletter = (campaignId) => {
  return {
    type: actionTypes.GET_NEWSLETTER,
    campaignId,
  };
};

export const getNewsletterSuccess = (response) => {
  return {
    type: actionTypes.GET_NEWSLETTER_SUCCESS,
    response,
  };
};

export const newsletterFail = (error) => {
  return {
    type: actionTypes.NEWSLETTER_FAIL,
    error,
  };
};

export const deleteNewsletter = (response) => {
  return {
    type: actionTypes.DELETE_NEWSLETTER,
    response,
  };
};

export const deleteNewsletterSuccess = (response) => {
  return {
    type: actionTypes.DELETE_NEWSLETTER_SUCCESS,
    response,
  };
};

export const getSearchNewsletters = (response,limitNo) => {
  return {
    type: actionTypes.GET_SEARCH_NEWSLETTERS,
    response,limitNo
  };
};
