import { put, call } from "redux-saga/effects";
import {
  SAVED_INVESTMENT_LIST,
  DELETE_SAVED_INVESTMENT,
} from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import * as actions from "../../actions/";
import { getSavedInvestmentCSVListSaga } from "../exportCsv/exportCsvSaga";

export function* getSavedInvestments(action) {
  yield put(actions.savedInvestmentStart());
  try {
    const response = yield axios.get(
      `${SAVED_INVESTMENT_LIST}/${action.response.user}/admin`,
      {
        params: { limit: 10 * action.response.limit },
      }
    );
    yield put(actions.getSavedInvestmentSuccess(response?.data));
  } catch (error) {
    yield put(actions.savedInvestmentFail(error));
  }
}
export function* getSearchSavedInvestmentSaga(action) {
  yield put(actions.savedInvestmentStart());
  try {
    const response = yield axios.get(SAVED_INVESTMENT_LIST, {
      params: {
        ...(action.response.campaign !== "" && {
          campaignName: action.response.campaign,
        }),
        ...(action.response.name !== "" && {
          user: action.response.investor,
        }),
        ...(action.limitNo !== "" && {
          limit: 10 * action.limitNo,
        }),
      },
    });
    yield put(actions.getSavedInvestmentSuccess(response?.data));
  } catch (error) {
    yield put(actions.savedInvestmentFail(error));
  }
}
export function* deleteSavedInvestment(action) {
  try {
    yield axios.delete(`${DELETE_SAVED_INVESTMENT}${action.id}/admin`);
    action.response = { limit: 1, user: action.user };
    yield call(getSavedInvestments, action);
    yield call(getSavedInvestmentCSVListSaga);
  } catch (error) {
    console.log("in error ", error);
    yield put(actions.savedInvestmentFail(error));
  }
}
