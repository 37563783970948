import * as actionTypes from "./actionTypes";

export const recaptchaStart = () => {
  return {
    type: actionTypes.RECAPTCHA_START,
  };
};

export const recaptchaFail = (error) => {
  return {
    type: actionTypes.RECAPTCHA_FAIL,
    error,
  };
};

export const getRecaptcha = () => {
  return {
    type: actionTypes.GET_RECAPTCHA,
  };
};

export const getRecaptchaSuccess = (response) => {
  return {
    type: actionTypes.GET_RECAPTCHA_SUCCESS,
    response,
  };
};

export const getPublicRecaptcha = () => {
  return {
    type: actionTypes.GET_PUBLIC_RECAPTCHA,
  };
};

export const getPublicRecaptchaSuccess = (response) => {
  return {
    type: actionTypes.GET_PUBLIC_RECAPTCHA_SUCCESS,
    response,
  };
};

export const createRecaptcha = (recaptcha) => {
  return {
    type: actionTypes.CREATE_RECAPTCHA,
    recaptcha,
  };
};

export const updateRecaptcha = (recaptcha) => {
  return {
    type: actionTypes.UPDATE_RECAPTCHA,
    recaptcha,
  };
};

/**
 * @description A commong action to store response from SAGA for creating / updating
 * reCaptcha values
 * @function recaptchaSuccess
 * @param {JSON} response `JSON`
 */

export const recaptchaSuccess = (response) => {
  return {
    type: actionTypes.RECAPTCHA_SUCCESS,
    response,
  };
};
