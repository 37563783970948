/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useLayoutEffect, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardCount, getDashboardCampaignsRevenues, getDashboardCampaignTotalInvestment, getDashboardCampaignTotalRepayment } from "../../../../store/actions/dashboard/dashboardActions";

export function MixedWidget1({ className }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const [monthsRevenues, setMonthsRevenues] = useState([]);
  const [monthlyRevenuesTotal, setMonthlyRevenuesTotal] = useState([]);
  const [currentRevenueCurrency, setCurrentRevenueCurrency] = useState("");
  const { totalCampaignRevenues, dashboardSelectedCurrency } = useSelector((state) => state.dashboard);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(uiService.config, "js.colors.gray.gray500"),
      colorsGrayGray200: objectPath.get(uiService.config, "js.colors.gray.gray200"),
      colorsGrayGray300: objectPath.get(uiService.config, "js.colors.gray.gray300"),
      colorsThemeBaseDanger: objectPath.get(uiService.config, "js.colors.theme.base.danger"),
      colorsThemeBasePrimary: objectPath.get(uiService.config, "js.colors.theme.base.primary"),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    dispatch(getDashboardCount());
    dispatch(getDashboardCampaignsRevenues(dashboardSelectedCurrency));
    dispatch(getDashboardCampaignTotalInvestment(dashboardSelectedCurrency));
    dispatch(getDashboardCampaignTotalRepayment(dashboardSelectedCurrency));
  }, [dispatch, dashboardSelectedCurrency]);

  useLayoutEffect(() => {
    if (totalCampaignRevenues) {
      setCurrentRevenueCurrency(totalCampaignRevenues?.currentCurrencyData?.symbol);
      const monthsCampaignRevenues = totalCampaignRevenues?.revenueChartData?.map(function (item) {
        return item["_id"];
      });
      setMonthsRevenues(monthsCampaignRevenues);

      const campaignRevenuesTotal = totalCampaignRevenues?.revenueChartData?.map(function (item) {
        return item["totalEarnedFee"];
      });
      setMonthlyRevenuesTotal(campaignRevenuesTotal);
    }
  }, [totalCampaignRevenues]);

  useEffect(() => {
    if (layoutProps && monthsRevenues && monthlyRevenuesTotal && currentRevenueCurrency) {
      const element = document.getElementById("kt_mixed_widget_1_chart");
      if (!element) {
        return;
      }

      const options = getChartOptions(layoutProps, monthsRevenues, monthlyRevenuesTotal, currentRevenueCurrency);
      const chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [layoutProps, monthsRevenues, monthlyRevenuesTotal, currentRevenueCurrency]);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-primary py-5">
        <h3 className="card-title font-weight-bolder text-white">Revenue</h3>
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative">
        {/* Chart */}
        <div id="kt_mixed_widget_1_chart" className="card-rounded-bottom bg-primary" style={{ height: "200px" }}></div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions(layoutProps, monthsRevenues, monthlyRevenuesTotal, currentRevenueCurrency) {
  const strokeColor = "#4031a2";
  const options = {
    series: [
      {
        name: "Revenue",
        data: monthlyRevenuesTotal,
      },
    ],
    chart: {
      type: "area",
      height: 200,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        enabledOnSeries: undefined,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
      opacity: 0,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories: monthsRevenues,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: "layoutProps.colorsGrayGray500",
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: layoutProps.colorsGrayGray300,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      //min: 0,
      // max: 80,
      labels: {
        show: false,
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return `${currentRevenueCurrency}` + val.toLocaleString();
        },
      },
      marker: {
        show: false,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: layoutProps.colorsThemeBasePrimary,
      strokeColor: [strokeColor],
      strokeWidth: 3,
    },
  };
  return options;
}
