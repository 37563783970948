export const getURL = () => {
  const url = document.location.href;
  return url.substring(url.lastIndexOf("/") + 1);
};

export const getSiteBaseURL = () => {
  const siteUrl = `${process.env.REACT_APP_FRONT_BASE_URL}/`;
  return siteUrl;
};

export const getSiteHost = () => {
  const siteHost = `${process.env.REACT_APP_ADMIN_BASE_URL}`;
  return siteHost;
};

export const doDemoModeOnOff = () => {
  const password = getURL();
  if (password === "rockers137") {
    localStorage.setItem("DEMO_MODE", false);
  } else {
    const url = document.location.href;
    const siteHost = getSiteHost();
    if (url.lastIndexOf(siteHost) !== -1) {
      localStorage.setItem("DEMO_MODE", false);
    } else {
      localStorage.setItem("DEMO_MODE", false);
    }
  }
};

export function getDemoMode() {
  if (localStorage.getItem("DEMO_MODE") === null) {
    doDemoModeOnOff();
  }
  return localStorage.getItem("DEMO_MODE");
}

export const getBaseImageURL = () => {
  const url = `${process.env.REACT_APP_AWS_URL}/`;
  return url;
};
