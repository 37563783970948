import * as actionTypes from "../../actions/roleManagement/actionTypes";
import { updateObject } from "../../utility/utility";
const initialState = {
  loading: false,
  role: [],
  roles: [],
  error: null,
  isCreated: false,
  isUpdated: false,
  currentPermissions: [],
};

const roleManagementStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    isCreated: false,
    isUpdated: false,
  });
};

const roleManagementFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const currentPermission = (state, action) => {
  return updateObject(state, {
    currentPermissions: action.response,
  });
};

const getRoles = (state, action) => {
  return updateObject(state, {
    roles: action.response,
    loading: false,
  });
};

const getRole = (state, action) => {
  return updateObject(state, {
    role: action.response,
  });
};

const clearRole = (state, action) => {
  return updateObject(state, {
    role: [],
  });
};

const isCreated = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

const isUpdated = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROLE_START:
      return roleManagementStart(state, action);
    case actionTypes.ROLE_FAIL:
      return roleManagementFail(state, action);
    case actionTypes.GET_ROLE_SUCCESS:
      return getRole(state, action);
    case actionTypes.CLEAR_ROLE:
        return clearRole(state, action);
    case actionTypes.GET_ROLES_SUCCESS:
      return getRoles(state, action);
    case actionTypes.CREATE_ROLE_SUCCESS:
      return isCreated(state, action);
    case actionTypes.UPDATE_ROLE_SUCCESS:
      return isUpdated(state, action);
    case actionTypes.GET_PERMISSIONS_SUCCESS:
      return currentPermission(state, action);
    default:
      return state;
  }
};

export default reducer;
