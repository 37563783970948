import * as actionTypes from "./actionTypes";

export const roleStart = () => {
  return {
    type: actionTypes.ROLE_START,
  };
};

export const roleFail = (error) => {
  return {
    type: actionTypes.ROLE_FAIL,
    error,
  };
};

export const getRole = (id) => {
  return {
    type: actionTypes.GET_ROLE,
    id,
  };
};

export const getRoleSuccess = (response) => {
  return {
    type: actionTypes.GET_ROLE_SUCCESS,
    response,
  };
};

export const clearRole = () => {
  return {
    type: actionTypes.CLEAR_ROLE
  };
};


export const getRoles = () => {
  return {
    type: actionTypes.GET_ROLES,
  };
};

export const getRolesSuccess = (response) => {
  return {
    type: actionTypes.GET_ROLES_SUCCESS,
    response,
  };
};

export const createRole = (role) => {
  return {
    type: actionTypes.CREATE_ROLE,
    role,
  };
};

export const createRoleSuccess = (response) => {
  return {
    type: actionTypes.CREATE_ROLE_SUCCESS,
    response,
  };
};

export const updateRole = (role) => {
  return {
    type: actionTypes.UPDATE_ROLE,
    role,
  };
};

export const updateRoleSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_ROLE_SUCCESS,
    response,
  };
};

export const deleteRole = (id) => {
  return {
    type: actionTypes.DELETE_ROLE,
    id,
  };
};

export const bulkUpdateRole = (role) => {
  return {
    type: actionTypes.BULK_UPDATE_ROLE,
    role,
  };
};

export const bulkUpdateRoleSuccess = (response) => {
  return {
    type: actionTypes.BULK_UPDATE_ROLE_SUCCESS,
    response,
  };
};

export const bulkDeleteRole = (role) => {
  return {
    type: actionTypes.BULK_DELETE_ROLE,
    role,
  };
};

export const getPermission = (id) => {
  return {
    type: actionTypes.GET_PERMISSIONS,
    id,
  };
};

export const getPermissionSuccess = (response) => {
  return {
    type: actionTypes.GET_PERMISSIONS_SUCCESS,
    response,
  };
};
