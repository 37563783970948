import * as actionTypes from "./actionTypes";

export const contePagesStart = () => {
  return {
    type: actionTypes.CONTENT_PAGES_START,
  };
};

export const contentPageFail = (error) => {
  return {
    type: actionTypes.CONTENT_PAGES_FAIL,
    error,
  };
};

export const getFAQs = (language) => {
  return {
    type: actionTypes.GET_FAQS,
    language,
  };
};

export const getFAQsSuccess = (response) => {
  return {
    type: actionTypes.GET_FAQS_SUCCESS,
    response,
  };
};

export const createFAQ = (faq, language) => {
  return {
    type: actionTypes.CREATE_FAQ,
    faq,
    language
  };
};

export const createFAQSuccess = (response) => {
  return {
    type: actionTypes.CREATE_FAQ_SUCCESS,
    response,
  };
};

export const deleteFaq = (id, language) => {
  return {
    type: actionTypes.DELETE_FAQ,
    id,
    language
  };
};

export const getFAQ = (id) => {
  return {
    type: actionTypes.GET_FAQ,
    id,
  };
};

export const getFAQSuccess = (response) => {
  return {
    type: actionTypes.GET_FAQ_SUCCESS,
    response,
  };
};

export const updateFAQ = (faq, language) => {
  return {
    type: actionTypes.UPDATE_FAQ,
    faq,
    language
  };
};

export const updateFAQSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_FAQ_SUCCESS,
    response,
  };
};

export const getPages = (language, title, pageLimit) => {
  return {
    type: actionTypes.GET_PAGES,
    language,
    title,
    pageLimit,
  };
};

export const getPagesSuccess = (response) => {
  return {
    type: actionTypes.GET_PAGES_SUCCESS,
    response,
  };
};

export const createPage = (page) => {
  return {
    type: actionTypes.CREATE_PAGE,
    page,
  };
};

export const createPageSuccess = (response) => {
  return {
    type: actionTypes.CREATE_PAGE_SUCCESS,
    response,
  };
};

export const deletePage = (id, pageLimit, language) => {
  return {
    type: actionTypes.DELETE_PAGE,
    id,
    pageLimit,
    language
  };
};

export const getPage = (id, language) => {
  return {
    type: actionTypes.GET_PAGE,
    id,
    language
  };
};

export const getPageSuccess = (response) => {
  return {
    type: actionTypes.GET_PAGE_SUCCESS,
    response,
  };
};

export const updatePage = (page) => {
  return {
    type: actionTypes.UPDATE_PAGE,
    page,
  };
};

export const updatePageSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_PAGE_SUCCESS,
    response,
  };
};

export const getHelpCategories = (language, status) => {
  return {
    type: actionTypes.GET_HELP_CATEGORIES,
    language,
    status,
  };
};

export const getHelpCategoriesSuccess = (response) => {
  return {
    type: actionTypes.GET_HELP_CATEGORIES_SUCCESS,
    response,
  };
};

export const getHelpCategory = (id) => {
  return {
    type: actionTypes.GET_HELP_CATEGORY,
    id,
  };
};

export const getHelpCategorySuccess = (response) => {
  return {
    type: actionTypes.GET_HELP_CATEGORY_SUCCESS,
    response,
  };
};

export const createHelpCategory = (help) => {
  return {
    type: actionTypes.CREATE_HELP_CATEGORY,
    help,
  };
};

export const createHelpCategorySuccess = (response) => {
  return {
    type: actionTypes.CREATE_HELP_CATEGORY_SUCCESS,
    response,
  };
};

export const deleteHelpCategory = (id) => {
  return {
    type: actionTypes.DELETE_HELP_CATEGORY,
    id,
  };
};

export const updateHelpCategory = (help) => {
  return {
    type: actionTypes.UPDATE_HELP_CATEGORY,
    help,
  };
};

export const updateHelpCategorySuccess = (response) => {
  return {
    type: actionTypes.UPDATE_HELP_CATEGORY_SUCCESS,
    response,
  };
};

export const getHelpCenters = (language) => {
  return {
    type: actionTypes.GET_HELP_CENTERS,
    language,
  };
};

export const getHelpCentersSuccess = (response) => {
  return {
    type: actionTypes.GET_HELP_CENTERS_SUCCESS,
    response,
  };
};

export const deleteHelpCenter = (id) => {
  return {
    type: actionTypes.DELETE_HELP_CENTER,
    id,
  };
};

export const createHelpCenter = (help) => {
  return {
    type: actionTypes.CREATE_HELP_CENTER,
    help,
  };
};

export const createHelpCenterSuccess = (response) => {
  return {
    type: actionTypes.CREATE_HELP_CENTER_SUCCESS,
    response,
  };
};

export const getHelpCenter = (id) => {
  return {
    type: actionTypes.GET_HELP_CENTER,
    id,
  };
};

export const getHelpCenterSuccess = (response) => {
  return {
    type: actionTypes.GET_HELP_CENTER_SUCCCESS,
    response,
  };
};

export const updateHelpCenter = (help) => {
  return {
    type: actionTypes.UPDATE_HELP_CENTER,
    help,
  };
};

export const updateHelpCenterSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_HELP_CENTER_SUCCESS,
    response,
  };
};


export const getFooterFAQs = (language, pageLimit, searchData) => {
  return {
    type: actionTypes.GET_FOOTER_FAQS,
    language,
    pageLimit,
    searchData
  };
};

export const getFooterFAQsSuccess = (response) => {
  return {
    type: actionTypes.GET_FOOTER_FAQS_SUCCESS,
    response,
  };
};

export const createFooterFAQ = (faq) => {
  return {
    type: actionTypes.CREATE_FOOTER_FAQ,
    faq,
  };
};

export const createFooterFAQSuccess = (response) => {
  return {
    type: actionTypes.CREATE_FOOTER_FAQ_SUCCESS,
    response,
  };
};

export const deleteFooterFaq = (id, language, pageLimit, searchData) => {
  return {
    type: actionTypes.DELETE_FOOTER_FAQ,
    id,
    language,
    pageLimit,
    searchData
  };
};

export const getFooterFAQ = (id) => {
  return {
    type: actionTypes.GET_FOOTER_FAQ,
    id,
  };
};

export const getFooterFAQSuccess = (response) => {
  return {
    type: actionTypes.GET_FOOTER_FAQ_SUCCESS,
    response,
  };
};

export const updateFooterFAQ = (faq) => {
  return {
    type: actionTypes.UPDATE_FOOTER_FAQ,
    faq,
  };
};

export const updateFooterFAQSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_FOOTER_FAQ_SUCCESS,
    response,
  };
};

export const getInvestorContracts = (language) => {
  return {
    type: actionTypes.GET_INVESTOR_CONTRACTS,
    language
  };
};

export const getInvestorContractsSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_CONTRACTS_SUCCESS,
    response,
  };
};

export const getInvestorContractById = (contractId) => {
  return {
    type: actionTypes.GET_INVESTOR_CONTRACT_BY_ID,
    contractId
  };
};

export const getInvestorContractByIdSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_CONTRACT_BY_ID_SUCCESS,
    response
  };
};

export const updateInvestorContract = (investorContract) => {
  return {
    type: actionTypes.UPDATE_INVESTOR_CONTRACT,
    investorContract
  };
};

export const updateInvestorContractSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_INVESTOR_CONTRACT_SUCCESS,
    response
  };
};