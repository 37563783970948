import * as actionTypes from "./actionTypes";

export const metaStart = () => {
  return {
    type: actionTypes.META_START,
  };
};

export const metaFail = (error) => {
  return {
    type: actionTypes.META_FAIL,
    error,
  };
};

export const getMeta = (language) => {
  return {
    type: actionTypes.GET_META,
    language,
  };
};

export const getMetaSuccess = (response) => {
  return {
    type: actionTypes.GET_META_SUCCESS,
    response,
  };
};

export const createMeta = (meta) => {
  return {
    type: actionTypes.CREATE_META,
    meta,
  };
};

export const createMetaSuccess = (response) => {
  return {
    type: actionTypes.CREATE_META_SUCCESS,
    response,
  };
};

export const updateMeta = (meta) => {
  return {
    type: actionTypes.UPDATE_META,
    meta,
  };
};

export const updateMetaSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_META_SUCCESS,
    response,
  };
};
