import { put } from "redux-saga/effects";
import { REVENUE_API } from "../../actions/apiCollections";
import axios from "../../../config/AxiosConfig";
import { revenueStart, revenueFail, getRevenueSuccess, successCommon } from "../../actions/revenue/revenueActions";

export function* getRevenue() {
  yield put(revenueStart());
  try {
    const response = yield axios.get(REVENUE_API);
    yield put(getRevenueSuccess(response.data.data.data));
  } catch (error) {
    yield put(revenueFail(error));
  }
}

export function* updateRevenue(action) {
  yield put(revenueStart());
  try {
    const response = yield axios.patch(`${REVENUE_API}${action.revenue.id}`, action.revenue);
    yield put(successCommon(response.data.data.data));
  } catch (error) {
    yield put(revenueFail(error));
  }
}

export function* createRevenue(action) {
  yield put(revenueStart());
  try {
    const response = yield axios.post(REVENUE_API, action.revenue);
    yield put(successCommon(response.data.data.data));
  } catch (error) {
    yield put(revenueFail(error));
  }
}
