import * as actionTypes from "./actionTypes";

export const getMangopayWalletTransactions = (userId, pageLimit, currency) => {
  return {
    type: actionTypes.GET_MANGOPAY_WALLET_TRANSACTIONS,
    userId,
    pageLimit,
    currency,
  };
};

export const getMangopayWalletTransactionsSuccess = (response) => {
  return {
    type: actionTypes.GET_MANGOPAY_WALLET_TRANSACTIONS_SUCCESS,
    response,
  };
};

export const getMangopayWalletDetail = (userId, currency) => {
  return {
    type: actionTypes.GET_MANGOPAY_WALLET_DETAIL,
    userId,
    currency,
  };
};

export const getMangopayWalletDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_MANGOPAY_WALLET_DETAIL_SUCCESS,
    response,
  };
};

export const mangopayWalletFail = (response) => {
  return {
    type: actionTypes.MANGOPAY_WALLET_FAIL,
    response,
  };
};

export const mangopayWalletStart = (pageLimit) => {
  return {
    type: actionTypes.MANGOPAY_WALLET_START,
    pageLimit,
  };
};
