import * as actionTypes from "../../actions/apiManagement/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  payments: [],
  error: null,
  isCreated: false,
};

const paymentGatewayStart = (state, action) => {
  return updateObject(state, { error: null, payments: [], isCreated: false });
};

const getPayment = (state, action) => {
  return updateObject(state, {
    payments: action.response,
  });
};

const paymentGatewayError = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const paymentGatewayCreated = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAYMENT_GATEWAY_START:
      return paymentGatewayStart(state, action);
    case actionTypes.GET_PAYMENT_GATEWAY_SUCCESSFUL:
      return getPayment(state, action);
    case actionTypes.PAYMENT_GATEWAY_FAIL:
      return paymentGatewayError(state, action);
    case actionTypes.PAYMENT_GATEWAY_SUCCESS:
      return paymentGatewayCreated(state, action);
    default:
      return state;
  }
};

export default reducer;
