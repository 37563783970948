import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { updateSocialSuccess, createSocialSuccess, getSocialSuccess, socialStart, socialFail } from "../../actions/apiManagement/socialSignup";
import { SOCIAL_SIGNUP_API } from "../../actions/apiCollections";

export function* getSocialSaga(action) {
  yield put(socialStart());
  try {
    const response = yield axios.get(`${SOCIAL_SIGNUP_API}${action.service}`);
    yield put(getSocialSuccess(response.data.data.data));
  } catch (error) {
    yield put(socialFail(error));
  }
}

export function* createSocialSaga(action) {
  try {
    const response = yield axios.post(`${SOCIAL_SIGNUP_API}${action.service.socialType}`, action.service);
    yield put(createSocialSuccess(response.data.data.data));
  } catch (error) {
    yield put(socialFail(error.response));
  }
}

export function* updateSocialSaga(action) {
  try {
    const response = yield axios.patch(`${SOCIAL_SIGNUP_API}${action.service.socialType}/${action.service.id}`, action.service);
    yield put(updateSocialSuccess(response.data.data.data));
  } catch (error) {
    yield put(socialFail(error.response));
  }
}
