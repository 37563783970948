import * as actionTypes from "../../actions/wallet/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  loadingTransactions: false,
  isSpinning: false,
  updateWalletTransactionMsg: false,
  walletTransactions: [],
};

const getWalletTransactionsSuccess = (state, action) => {
  return updateObject(state, {
    walletTransactions: action.response,
    loadingTransactions: false,
    isSpinning: false,
    updateWalletTransactionMsg: false,
  });
};

const updateWalletTransactionSuccess = (state, action) => {
  return updateObject(state, {
    updateWalletTransactionMsg: true,
  });
};

const walletFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loadingTransactions: false,
    isSpinning: false,
  });
};

const walletStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loadingTransactions: action?.pageLimit === 1 ? true : false,
    isSpinning: action?.pageLimit === 1 ? false : true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // eslint-disable-next-line
    case actionTypes.GET_WALLET_TRANSACTIONS_SUCCESS:
      return getWalletTransactionsSuccess(state, action);
    case actionTypes.GET_WALLET_TRANSACTIONS_SUCCESS:
      return updateWalletTransactionSuccess(state, action);
    case actionTypes.WALLET_FAIL:
      return walletFail(state, action);
    case actionTypes.WALLET_START:
      return walletStart(state, action);

    default:
      return state;
  }
};

export default reducer;
