export const DASHBOARD_START = "[DASHBOARD COMPONENT] Dashboard Start";
export const DASHBOARD_FAIL = "[DASHBOARD COMPONENT] Dashboard Fail";
export const GET_DASHBOARD_COUNT = "[DASHBOARD COMPONENT] Get Dashboard Count";
export const GET_DASHBOARD_COUNT_SUCCESS =
  "[DASHBOARD COMPONENT] Get Dashboard Count Success";
export const GET_DASHBOARD_PENDING_CAMPAIGNS =
  "[DASHBOARD COMPONENT] Get Dashboard Pending Campaigns";
export const GET_DASHBOARD_PENDING_CAMPAIGNS_SUCCESS =
  "[DASHBOARD COMPONENT] Get Dashboard Pending Campaigns Success";

export const GET_DASHBOARD_CAMPAIGNS_REVENUES =
  "[DASHBOARD COMPONENT] GET_DASHBOARD_CAMPAIGNS_REVENUES";
export const GET_DASHBOARD_CAMPAIGNS_REVENUES_SUCCESS =
  "[DASHBOARD COMPONENT] GET_DASHBOARD_CAMPAIGNS_REVENUES_SUCCESS";

export const GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT =
  "[DASHBOARD COMPONENT] GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT";
export const GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT_SUCCESS =
  "[DASHBOARD COMPONENT] GET_DASHBOARD_CAMPAIGN_TOTAL_INVESTMENT_SUCCESS";

export const GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT =
  "[DASHBOARD COMPONENT] GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT";
export const GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT_SUCCESS =
  "[DASHBOARD COMPONENT] GET_DASHBOARD_CAMPAIGN_TOTAL_REPAYMENT_SUCCESS";

  export const SET_DASHBOARD_SELECTED_CURRENCY =
  "[DASHBOARD COMPONENT] SET_DASHBOARD_SELECTED_CURRENCY";
