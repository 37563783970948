import * as actionTypes from "../../actions/emailSettings/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  emailTemplates: [],
  emailTemplate: [],
  isCreated: false,
  isUpdated: false,
  updateMasterEmailSuccessMsg: null,
  sendTestEmailSuccessMsg: null
};

const emailStart = (state, action) => {
  return updateObject(state, {
    error: [],
    isCreated: false,
    isUpdated: false,
    updateMasterEmailSuccessMsg: null
  });
};

const emailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    updateMasterEmailSuccessMsg: null
  });
};

const getEmailTemplates = (state, action) => {
  return updateObject(state, {
    emailTemplates: action.response,
    updateMasterEmailSuccessMsg: null
  });
};

const getEmailTemplate = (state, action) => {
  return updateObject(state, {
    emailTemplate: action.response,
  });
};

const createEmailTemplate = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

const sendTestEmailSuccess  = (state, action) => {
  return updateObject(state, {
    sendTestEmailSuccessMsg: action.response,
  });
};

const updateMasterEmailSuccess  = (state, action) => {
  return updateObject(state, {
    updateMasterEmailSuccessMsg: action.response,
  });
};

const updateEmailTemplate = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMAIL_SETTINGS_START:
      return emailStart(state, action);
    case actionTypes.EMAIL_SETTINGS_FAIL:
      return emailFail(state, action);
    case actionTypes.GET_EMAIL_TEMPLATES_SUCCESS:
      return getEmailTemplates(state, action);
    case actionTypes.GET_EMAIL_TEMPLATE_SUCCESS:
      return getEmailTemplate(state, action);
    case actionTypes.CREATE_EMAIL_TEMPlATE_SUCCESS:
      return createEmailTemplate(state, action);
    case actionTypes.SEND_TEST_EMAIL_SUCCESS:
      return sendTestEmailSuccess(state, action);
    case actionTypes.UPDATE_MASTER_EMAIL_SUCCESS:
      return updateMasterEmailSuccess(state, action);
    case actionTypes.UPDATE_EMAIL_TEMPlATE_SUCCESS:
    case actionTypes.BULK_UPDATE_EMAIL_TEMPlATE_SUCCESS:
      return updateEmailTemplate(state, action);
    default:
      return state;
  }
};

export default reducer;
