import { put, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { CRON_JOB_API } from "../../actions/apiCollections";
import { cronJobStart, cronJobFail, getCronJobsSuccess, createCronJobSuccess } from "../../actions/cron/cronJobActions";

export function* getCronJobsSaga() {
  try {
    const response = yield axios.get(CRON_JOB_API);
    yield put(getCronJobsSuccess(response.data.data.data.docs));
  } catch (error) {
    yield put(cronJobFail(error));
  }
}

export function* createCronJobSaga(action) {
  yield put(cronJobStart());
  try {
    const response = yield axios.post(CRON_JOB_API, action);
    yield put(createCronJobSuccess(response.data.data.data));
    yield call(getCronJobsSaga);
  } catch (error) {
    yield put(cronJobFail(error));
  }
}
