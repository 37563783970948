/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../../layout";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardCount,
  getDashboardCampaignsRevenues,
  getDashboardCampaignTotalInvestment,
  getDashboardCampaignTotalRepayment,
} from "../../../../store/actions/dashboard/dashboardActions";
import { formatCurrency } from "../../../../helpers/numberFormat";

export function StatsWidget1({ className, taxonomy }) {
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const [monthsRevenues, setMonthsRevenues] = useState([]);
  const [monthlyRevenuesTotal, setMonthlyRevenuesTotal] = useState([]);

  const {
    dashboardCount,
    totalCampaignRevenues,
    totalCampaignInvestments,
    totalCampaignRePayment,
    dashboardSelectedCurrency,
  } = useSelector((state) => state.dashboard);
  const { generalSettings } = useSelector((state) => state.settings);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      colorsThemeBasePrimary: objectPath.get(
        uiService.config,
        "js.colors.theme.base.primary"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    dispatch(getDashboardCount());
    dispatch(getDashboardCampaignsRevenues(dashboardSelectedCurrency));
    dispatch(getDashboardCampaignTotalInvestment(dashboardSelectedCurrency));
    dispatch(getDashboardCampaignTotalRepayment(dashboardSelectedCurrency));
  }, [dispatch, dashboardSelectedCurrency]);

  useLayoutEffect(() => {
    if (totalCampaignRevenues) {
      const monthsCampaignRevenues =
        totalCampaignRevenues?.revenueChartData?.map(function (item) {
          return item["_id"];
        });
      setMonthsRevenues(monthsCampaignRevenues);

      const campaignRevenuesTotal =
        totalCampaignRevenues?.revenueChartData?.map(function (item) {
          return item["totalEarnedFee"];
        });
      setMonthlyRevenuesTotal(campaignRevenuesTotal);
    }
  }, [totalCampaignRevenues, layoutProps]);

  return (
    <div className={`${className}`}>
      <div className="row">
        <div className="col-3 pl-0">
          <div className="bg-light-warning p-8 rounded-xl ">
            <span className="svg-icon svg-icon-3x svg-icon-warning d-block">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                )}
              ></SVG>
            </span>
            <div className="text-warning font-weight-bolder font-size-h2 mt-3">
              {dashboardCount.activeCampaign}
            </div>
            <Link
              to="/campaign/campaigns"
              className="text-warning font-weight-bold font-size-h6"
            >
              Active {taxonomy?.projectPlural}
            </Link>
          </div>
        </div>
        <div className="col-3 pl-0">
          <div className="bg-light-primary p-8 rounded-xl ">
            <span className="svg-icon svg-icon-3x svg-icon-primary d-block">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              ></SVG>
            </span>
            <div className="text-primary font-weight-bolder font-size-h2 mt-3">
              {dashboardCount.newUsers}
            </div>
            <Link
              to="/user-management/front-end-users"
              className="text-primary font-weight-bold font-size-h6 mt-2"
            >
              New Users
            </Link>
          </div>
        </div>
        <div className="col-3 pl-0">
          <div className="bg-light-danger p-8 rounded-xl">
            <span className="svg-icon svg-icon-3x svg-icon-danger d-block">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
              ></SVG>
            </span>
            <div className="text-danger font-weight-bolder font-size-h2 mt-3">
              {formatCurrency(
                totalCampaignInvestments?.totalInvestment ?? 0,
                generalSettings?.currencySymbolSide,
                totalCampaignInvestments?.currentCurrencyData?.symbol ?? "",
                totalCampaignInvestments?.currentCurrencyData?.code ?? "",
                generalSettings?.decimalPoints
              )}
            </div>
            <Link
              to={"./transaction"}
              className="text-danger font-weight-bold font-size-h6 mt-2"
            >
              Investment
            </Link>
          </div>
        </div>
        <div className="col-3 pl-0">
          <div className="bg-light-success p-8 rounded-xl">
            <span className="text-success font-weight-bolder font-size-h2 d-block">
              {totalCampaignInvestments?.currentCurrencyData?.symbol}
            </span>
            <div className="text-success font-weight-bolder font-size-h2 mt-3">
              {formatCurrency(
                totalCampaignRePayment?.totalRepayment ?? 0,
                generalSettings?.currencySymbolSide,
                totalCampaignRePayment?.currentCurrencyData?.symbol ?? "",
                totalCampaignRePayment?.currentCurrencyData?.code ?? "",
                generalSettings?.decimalPoints
              )}
            </div>
            <span className="text-success font-weight-bold font-size-h6 mt-2">
              Re-Payment
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
