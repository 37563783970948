import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { PAYMENT_GATEWAY_API } from "../../actions/apiCollections";
import {
  paymentGatewayStart,
  paymentGatewayFail,
  getPaymentGatewaySuccessful,
  paymentGatewaySuccess,
} from "../../actions/apiManagement/paymentGateway";

export function* getPaymentGatewaySaga(action) {
  yield put(paymentGatewayStart());
  try {
    const response = yield axios.get(
      `${PAYMENT_GATEWAY_API}${action.paymentType}`
    );
    yield put(getPaymentGatewaySuccessful(response.data.data.data));
  } catch (error) {
    yield put(paymentGatewayFail(error.response));
  }
}

export function* createPaymentGatewaySaga(action) {
  try {
    const response = yield axios.post(
      `${PAYMENT_GATEWAY_API}${action.paymentDetails.paymentType}`,
      action.paymentDetails
    );
    yield put(paymentGatewaySuccess(response.data.data.data));
  } catch (error) {
    yield put(paymentGatewayFail(error.response));
  }
}

export function* updatePaymentGatewaySaga(action) {
  try {
    const response = yield axios.patch(
      `${PAYMENT_GATEWAY_API}${action.paymentDetails.paymentType}/${action.paymentDetails.id}`,
      action.paymentDetails
    );
    yield put(paymentGatewaySuccess(response.data.data.data));
  } catch (error) {
    yield put(paymentGatewayFail(error.response));
  }
}
