export const ROLE_START = "ROLE_START";
export const GET_ROLE = "GET_ROLE";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLES = "GET_ROLES";
export const CLEAR_ROLE = "CLEAR_ROLE";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const DELETE_ROLE = "DELETE_ROLE";
export const ROLE_FAIL = "ROLE_FAIL";
export const BULK_UPDATE_ROLE = "BULK_UPDATE_ROLE";
export const BULK_UPDATE_ROLE_SUCCESS = "BULK_UPDATE_ROLE_SUCCESS";
export const BULK_DELETE_ROLE = "BULK_DELETE_ROLE";
export const GET_PERMISSIONS = "[GLOBAL COMPONENT] GET PERMISSIONS";
export const GET_PERMISSIONS_SUCCESS = "[GLOBAL COMPONENT] GET PERMISSIONS SUCCESS";
