import * as actionTypes from "../../actions/cron/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: [],
  crons: [],
  isLoading: false,
};

const cronStart = (state, action) => {
  return updateObject(state, {
    error: [],
    isLoading: true,
  });
};

const cronFail = (state, action) => {
  return updateObject(state, {
    error: action.response,
  });
};

const getCronJobs = (state, action) => {
  return updateObject(state, {
    crons: action.response,
  });
};

const createCronJob = (state, action) => {
  return updateObject(state, {
    isLoading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CRON_START:
      return cronStart(state, action);
    case actionTypes.CRON_FAIL:
      return cronFail(state, action);
    case actionTypes.GET_CRON_JOBS_SUCCESS:
      return getCronJobs(state, action);
    case actionTypes.CREATE_CRON_JOB_SUCCESS:
      return createCronJob(state, action);
    default:
      return state;
  }
};

export default reducer;
