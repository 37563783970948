import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { getRecaptchaSuccess, recaptchaSuccess, recaptchaStart, recaptchaFail, getPublicRecaptchaSuccess } from "../../actions/apiManagement/recaptcha";
import { RECAPTCHA_API } from "../../actions/apiCollections";

export function* getRecaptchaSaga() {
  yield put(recaptchaStart());
  try {
    const response = yield axios.get(RECAPTCHA_API);
    yield put(getRecaptchaSuccess(response.data.data.data));
  } catch (error) {
    yield put(recaptchaFail(error.response.data.data.data));
  }
}

export function* getPublicRecaptchaSaga() {
  try {
    const response = yield axios.get(RECAPTCHA_API + "front");
    yield put(getPublicRecaptchaSuccess(response.data.data.data));
  } catch (error) {
    yield put(recaptchaFail(error.response.data.data.data));
  }
}

export function* createRecaptchaSaga(action) {
  yield put(recaptchaStart());
  try {
    const respone = yield axios.post(RECAPTCHA_API, action.recaptcha);
    yield put(recaptchaSuccess(respone.data.data.data));
  } catch (error) {
    yield put(recaptchaFail(error.response));
  }
}

export function* updateRecaptchaSaga(action) {
  yield put(recaptchaStart());
  try {
    const respone = yield axios.patch(`${RECAPTCHA_API}${action.recaptcha.id}`, action.recaptcha);
    yield put(recaptchaSuccess(respone.data.data.data));
  } catch (error) {
    yield put(recaptchaFail(error.response));
  }
}
